<template>
    <Setup-Step
        @next="f => f()"
        fluid
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        step="order-stock-consign"
        title="Stock Consignment">
        <template v-slot:body>
            <BT-Order-Cycle-Header showTrimmings highlightStockConsign title="Order Pipeline: Stock Consignment" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="picking-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Stock Consignment</v-subheader>

                        <p>
                            Once an order is processed, it creates a stock consignment.  
                            Stock consignments need to be assigned a supply location where the stock will be supplied from.  
                            This can be done automatically via supply pointers or manually via user input.  
                            You can also define this here globally for every customer or choose to define it individually for each customer.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.consignmentGuide == 'Settings' ? 'Apply To Every Customer' : 'Different For Each Customer'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.consignmentGuide" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.consignmentGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.consignmentTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.consignmentTrigger" />
                                    <p v-else>
                                        (Define this when you add new customers)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />

                        <v-subheader>Provisioning Stock</v-subheader>

                        <p>
                            Once a stock consignment has a supply location, stock can be assigned to it from that location.  
                            This can also be attempted automatically or manually.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.provisionGuide == 'Settings' ? 'Apply To Every Customer' : 'Different For Each Customer'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.provisionGuide" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.provisionGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.provisionTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.provisionTrigger" />
                                    <p v-else>
                                        (Define this when you add new customers)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>

                        <v-divider class="my-4" />

                        <v-subheader>Provisioning Strategy</v-subheader>

                        <p>
                            BlitzIt Web can use a couple different strategies when deciding what stock to use first.  You can decide this below.
                        </p>

                        <v-row>
                            <v-col cols="6">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-sync-circle"
                                    falseLabel="Freshest"
                                    falseValue="Freshest"
                                    :isEditing="item.provisionGuide == 'Settings'"
                                    outlined
                                    trueIcon="mdi-refresh-circle"
                                    trueLabel="First In First Out"
                                    trueValue="FirstInFirstOut"
                                    v-model="item.provisionStrategy" />
                            </v-col>
                            <!-- <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.provisionGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.provisionTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.provisionTrigger" />
                                    <p v-else>
                                        (Define this when you add new customers)
                                    </p>
                                </v-slide-x-transition>
                            </v-col> -->
                        </v-row>
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Stock-Consign-Setup',
    components: {
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        // BTSwitchTrigger: () => import('~components/BT-Switch-Trigger.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        // SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>